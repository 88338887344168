import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core"
import React from "react"
import { Layout, SEO } from "../components"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import { useStaticQuery, graphql } from "gatsby"

import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import FacebookIcon from "@material-ui/icons/Facebook"
import GitHubIcon from "@material-ui/icons/GitHub"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import { useWindowSize } from "../utils"

const useStyles = makeStyles({
  card: {
    margin: 6,
    padding: 10,
    minHeight: "230px",
    background:
      "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
    color: "white",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
  },
  hr: {
    backgroundColor: "white",
  },
  question: {
    fontWeight: "bolder",
  },
  answer: {
    marginLeft: "10px",
    fontWeight: "200",
  },
})

const contact = () => {
  const classes = useStyles()
  const { personal } = useStaticQuery(
    graphql`
      query MyContacts {
        personal: allContentfulPersonalInformation {
          edges {
            node {
              id
              name
              phoneNumbers {
                numbers
              }
              socials {
                Facebook
                Instagram
                GitHub
                LinkedIn
                Twitter
                WhatsApp
              }
              emails {
                work
                school
                development
              }
            }
          }
        }
      }
    `
  )
  const information = personal.edges[0]
  const { width } = useWindowSize()

  return (
    <Layout title={"Contact"}>
      <SEO title="Contact" previewImage={{ width: 267 }} />
      <Box mt={2} ml={3} mr={3}>
        <Box>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardContent style={{ padding: 30 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      style={{
                        fontSize:
                          width > 2100
                            ? "40px"
                            : width > 1024
                            ? "30px"
                            : width > 425
                            ? "24px"
                            : "18px",
                      }}
                      component="h2"
                    >
                      You can contact me on any social site or send an email to
                      any of my emails. 😊😊
                    </Typography>
                    <br />
                    <Grid
                      container
                      // style={{ width: "60%" }}
                      direction="column"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          style={{
                            fontSize:
                              width > 2100
                                ? "25px"
                                : width > 425
                                ? "17px"
                                : "13px",
                          }}
                          component="h4"
                        >
                          Emails & Phone
                        </Typography>
                        <Box mt={2} />
                        <hr className={classes.hr} />
                        <Box>
                          <Grid container>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                              <p className={classes.question}>
                                Phone:
                                <span className={classes.answer}>
                                  {information.node.phoneNumbers.numbers.join(
                                    ", "
                                  )}
                                </span>
                              </p>
                              <p className={classes.question}>
                                Emails:
                                <Grid
                                  container
                                  direction="column"
                                  justify="flex-start"
                                  alignItems="strech"
                                  style={{
                                    padding:
                                      width > 350 ? "20px 20px 0 20px" : "0",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <p className={classes.question}>
                                      Work:
                                      <span className={classes.answer}>
                                        {information.node.emails.development}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    item
                                    style={{ marginTop: 5 }}
                                  >
                                    <p className={classes.question}>
                                      Personal:
                                      <span className={classes.answer}>
                                        {information.node.emails.work}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    item
                                    style={{ marginTop: 5 }}
                                  >
                                    <p className={classes.question}>
                                      School:
                                      <span className={classes.answer}>
                                        {information.node.emails.school}
                                      </span>
                                    </p>
                                  </Grid>
                                </Grid>
                              </p>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          style={{
                            fontSize:
                              width > 2100
                                ? "25px"
                                : width > 425
                                ? "17px"
                                : "13px",
                          }}
                          gutterBottom
                          variant="h6"
                          component="h4"
                        >
                          Socials
                        </Typography>
                        <Box mt={2} />
                        <hr className={classes.hr} />
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Grid
                              container
                              direction="column"
                              justify="flex-start"
                              alignItems="strech"
                              style={{
                                padding:
                                  width > 500 ? "20px 20px 0 20px" : "10px 0",
                              }}
                            >
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                    }}
                                  >
                                    <p className={classes.question}>
                                      Facebook:
                                    </p>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                  >
                                    <span className={classes.answer}>
                                      <a
                                        href={information.node.socials.Facebook}
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <FacebookIcon /> &nbsp; Facebook
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                      marginTop: width < 500 ? "10px" : 0,
                                    }}
                                  >
                                    <p className={classes.question}>
                                      Instagram:
                                    </p>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className={classes.answer}>
                                      <a
                                        href={
                                          information.node.socials.Instagram
                                        }
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <InstagramIcon /> &nbsp; Instagram
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                      marginTop: width < 500 ? "10px" : 0,
                                    }}
                                  >
                                    <p className={classes.question}>GitHub:</p>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className={classes.answer}>
                                      <a
                                        href={information.node.socials.GitHub}
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <GitHubIcon /> &nbsp; GitHub
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Grid
                              container
                              direction="column"
                              justify="flex-start"
                              alignItems="strech"
                              style={{
                                padding:
                                  width > 500 ? "20px 20px 0 20px" : "10px 0",
                              }}
                            >
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                    }}
                                  >
                                    <p className={classes.question}>
                                      Whatsapp:
                                    </p>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className={classes.answer}>
                                      <a
                                        href={information.node.socials.WhatsApp}
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <WhatsAppIcon /> &nbsp; WhatsApp
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                      marginTop: width < 500 ? "10px" : 0,
                                    }}
                                  >
                                    <p className={classes.question}>
                                      LinkedIn:
                                    </p>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className={classes.answer}>
                                      <a
                                        href={information.node.socials.LinkedIn}
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <LinkedInIcon /> &nbsp; LinkedIn
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{
                                      padding: "8px",
                                      marginTop: width < 500 ? "10px" : 0,
                                    }}
                                  >
                                    <p className={classes.question}>Twitter:</p>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <span className={classes.answer}>
                                      <a
                                        href={information.node.socials.Twitter}
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                      >
                                        <Button
                                          size="large"
                                          style={{
                                            background: "rgb(255, 193, 7)",
                                            width:
                                              width > 500 ? "200px" : "160px",
                                          }}
                                        >
                                          <TwitterIcon /> &nbsp; Twitter
                                        </Button>
                                      </a>
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{
                        fontSize:
                          width > 2100 ? "22px" : width > 425 ? "17px" : "14px",
                      }}
                      component="h6"
                    >
                      I live in Karachi so you can also book a meeting with me.
                      Tea on me <span style={{ fontSize: "40px" }}>☕</span>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default contact
